module.exports = function(tagName) {
    function onLoaded(comp) {
      if (!comp.default) {
        throw new Error('Failed to load component <' + tagName + '>');
      }
      comp.default.__htmlTag = tagName;
    }
    
            if(tagName === 'greeting') {
              return import(/* webpackChunkName: "greeting"*/'platform/components/examples/GreetingComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-link') {
              return import(/* webpackChunkName: "semantic-link"*/'platform/api/navigation/components/ResourceLinkComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-link-container') {
              return import(/* webpackChunkName: "semantic-link-container"*/'platform/api/navigation/components/ResourceLinkContainer.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-table') {
              return import(/* webpackChunkName: "semantic-table"*/'platform/components/semantic/table/SemanticTable.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-table-row-selection') {
              return import(/* webpackChunkName: "semantic-table-row-selection"*/'platform/components/semantic/table/TableSelectionComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-query') {
              return import(/* webpackChunkName: "semantic-query"*/'platform/components/semantic/query/SemanticQuery.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-carousel') {
              return import(/* webpackChunkName: "semantic-carousel"*/'platform/components/semantic/carousel/SemanticCarousel.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-chart') {
              return import(/* webpackChunkName: "semantic-chart"*/'platform/components/semantic/chart/SemanticChart.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-chart-type-selector') {
              return import(/* webpackChunkName: "semantic-chart-type-selector"*/'platform/components/semantic/chart/SemanticChartTypeSelector.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-tree') {
              return import(/* webpackChunkName: "semantic-tree"*/'platform/components/semantic/tree/SemanticTree.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-lazy-tree') {
              return import(/* webpackChunkName: "semantic-lazy-tree"*/'platform/components/semantic/lazy-tree/LazyTree.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-map') {
              return import(/* webpackChunkName: "semantic-map"*/'platform/components/semantic/map/SemanticMap.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-graph') {
              return import(/* webpackChunkName: "semantic-graph"*/'platform/components/semantic/graph/SemanticGraph.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-graph-extension-expand-collapse') {
              return import(/* webpackChunkName: "semantic-graph-extension-expand-collapse"*/'platform/components/semantic/graph/extensions/CytoscapeExpandCollapse.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-graph-extension-navigator') {
              return import(/* webpackChunkName: "semantic-graph-extension-navigator"*/'platform/components/semantic/graph/extensions/CytoscapeNavigator.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-graph-extension-panzoom') {
              return import(/* webpackChunkName: "semantic-graph-extension-panzoom"*/'platform/components/semantic/graph/extensions/CytoscapePanZoomExtension.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-graph-layout-breadthfirst') {
              return import(/* webpackChunkName: "semantic-graph-layout-breadthfirst"*/'platform/components/semantic/graph/layouts/BreadthFirstLayout.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-graph-layout-circle') {
              return import(/* webpackChunkName: "semantic-graph-layout-circle"*/'platform/components/semantic/graph/layouts/CircleLayout.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-graph-layout-concentric') {
              return import(/* webpackChunkName: "semantic-graph-layout-concentric"*/'platform/components/semantic/graph/layouts/ConcentricLayout.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-graph-layout-cose-bilkent') {
              return import(/* webpackChunkName: "semantic-graph-layout-cose-bilkent"*/'platform/components/semantic/graph/layouts/CoseBilkentLayout.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-graph-layout-cose') {
              return import(/* webpackChunkName: "semantic-graph-layout-cose"*/'platform/components/semantic/graph/layouts/CoseLayout.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-graph-layout-grid') {
              return import(/* webpackChunkName: "semantic-graph-layout-grid"*/'platform/components/semantic/graph/layouts/GridLayout.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-graph-layout-preset') {
              return import(/* webpackChunkName: "semantic-graph-layout-preset"*/'platform/components/semantic/graph/layouts/PresetLayout.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-radar-plot') {
              return import(/* webpackChunkName: "mp-radar-plot"*/'platform/components/semantic/radar-plot/RadarPlot.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search') {
              return import(/* webpackChunkName: "semantic-search"*/'platform/components/semantic/search/web-components/SemanticSearch.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-query-keyword') {
              return import(/* webpackChunkName: "semantic-search-query-keyword"*/'platform/components/semantic/search/web-components/KeywordSearch.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-query-entities') {
              return import(/* webpackChunkName: "semantic-search-query-entities"*/'platform/components/semantic/search/web-components/EntitySearch.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-query-constant') {
              return import(/* webpackChunkName: "semantic-search-query-constant"*/'platform/components/semantic/search/web-components/QueryConstant.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-result') {
              return import(/* webpackChunkName: "semantic-search-result"*/'platform/components/semantic/search/web-components/SemanticSearchResult.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-result-holder') {
              return import(/* webpackChunkName: "semantic-search-result-holder"*/'platform/components/semantic/search/web-components/SemanticSearchResultHolder.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-form-query') {
              return import(/* webpackChunkName: "semantic-search-form-query"*/'platform/components/semantic/search/web-components/SemanticSearchFormQuery.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-domain-switch') {
              return import(/* webpackChunkName: "semantic-search-domain-switch"*/'platform/components/semantic/search/web-components/SemanticSearchDomainSwitch.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-table-result') {
              return import(/* webpackChunkName: "semantic-search-table-result"*/'platform/components/semantic/search/web-components/SemanticSearchTableResult.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-simple-search') {
              return import(/* webpackChunkName: "semantic-simple-search"*/'platform/components/semantic/simple-search/SimpleSearch.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-if') {
              return import(/* webpackChunkName: "semantic-if"*/'platform/components/semantic/conditional/SemanticIf.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-switch') {
              return import(/* webpackChunkName: "semantic-switch"*/'platform/components/semantic/conditional/SemanticSwitch.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-context') {
              return import(/* webpackChunkName: "semantic-context"*/'platform/api/components/SemanticContext.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-link') {
              return import(/* webpackChunkName: "mp-link"*/'platform/api/navigation/components/Link.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-resource-link') {
              return import(/* webpackChunkName: "mp-resource-link"*/'platform/api/navigation/components/ResourceLinkComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-resource-link-container') {
              return import(/* webpackChunkName: "mp-resource-link-container"*/'platform/api/navigation/components/ResourceLinkContainer.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-resource-thumbnail') {
              return import(/* webpackChunkName: "mp-resource-thumbnail"*/'platform/components/ui/thumbnail/ResourceThumbnail.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-resource-thumbnail-fallback') {
              return import(/* webpackChunkName: "mp-resource-thumbnail-fallback"*/'platform/components/ui/thumbnail/NoResourceThumbnail.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-navigate-to-resource') {
              return import(/* webpackChunkName: "mp-navigate-to-resource"*/'platform/api/navigation/components/NavigateToIri.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-event-trigger') {
              return import(/* webpackChunkName: "mp-event-trigger"*/'platform/components/events/EventTrigger.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-event-proxy') {
              return import(/* webpackChunkName: "mp-event-proxy"*/'platform/components/events/EventProxy.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-event-target-refresh') {
              return import(/* webpackChunkName: "mp-event-target-refresh"*/'platform/components/events/EventTargetRefresh.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-event-target-template-render') {
              return import(/* webpackChunkName: "mp-event-target-template-render"*/'platform/components/events/EventTargetTemplateRender.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-event-target-redirect') {
              return import(/* webpackChunkName: "mp-event-target-redirect"*/'platform/components/ui/redirect-action/RedirectAction.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'field-editor') {
              return import(/* webpackChunkName: "field-editor"*/'platform/components/forms/field-editor/FieldEditorComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form') {
              return import(/* webpackChunkName: "semantic-form"*/'platform/components/forms/ResourceEditorForm.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-switch') {
              return import(/* webpackChunkName: "semantic-form-switch"*/'platform/components/forms/inputs/FormSwitch.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-switch-case') {
              return import(/* webpackChunkName: "semantic-form-switch-case"*/'platform/components/forms/inputs/FormSwitchCase.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-text-input') {
              return import(/* webpackChunkName: "semantic-form-text-input"*/'platform/components/forms/inputs/PlainTextInput.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-hidden-input') {
              return import(/* webpackChunkName: "semantic-form-hidden-input"*/'platform/components/forms/inputs/HiddenInput.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-select-input') {
              return import(/* webpackChunkName: "semantic-form-select-input"*/'platform/components/forms/inputs/SelectInput.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-checklist-input') {
              return import(/* webpackChunkName: "semantic-form-checklist-input"*/'platform/components/forms/inputs/ChecklistInput.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-checkbox-input') {
              return import(/* webpackChunkName: "semantic-form-checkbox-input"*/'platform/components/forms/inputs/CheckboxInput.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-autocomplete-input') {
              return import(/* webpackChunkName: "semantic-form-autocomplete-input"*/'platform/components/forms/inputs/AutocompleteInput.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-datetime-input') {
              return import(/* webpackChunkName: "semantic-form-datetime-input"*/'platform/components/forms/inputs/DatePickerInput.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-recover-notification') {
              return import(/* webpackChunkName: "semantic-form-recover-notification"*/'platform/components/forms/static/RecoverNotification.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-errors') {
              return import(/* webpackChunkName: "semantic-form-errors"*/'platform/components/forms/static/FormErrors.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-field-description') {
              return import(/* webpackChunkName: "semantic-form-field-description"*/'platform/components/forms/static/Description.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-field-label') {
              return import(/* webpackChunkName: "semantic-form-field-label"*/'platform/components/forms/static/Label.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-composite-input') {
              return import(/* webpackChunkName: "semantic-form-composite-input"*/'platform/components/forms/inputs/CompositeInput.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-tree-picker-input') {
              return import(/* webpackChunkName: "semantic-form-tree-picker-input"*/'platform/components/forms/inputs/TreePickerInput.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-drag-and-drop-input') {
              return import(/* webpackChunkName: "semantic-form-drag-and-drop-input"*/'platform/components/forms/inputs/drop/DragAndDropInput.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-tree-input') {
              return import(/* webpackChunkName: "semantic-tree-input"*/'platform/components/semantic/lazy-tree/SemanticTreeInput.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-field-visualization') {
              return import(/* webpackChunkName: "mp-field-visualization"*/'platform/components/forms/FieldBasedVisualization.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-code-highlight') {
              return import(/* webpackChunkName: "mp-code-highlight"*/'platform/components/documentation/CodeHighlight.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-code-example') {
              return import(/* webpackChunkName: "mp-code-example"*/'platform/components/documentation/CodeExample.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-code-block') {
              return import(/* webpackChunkName: "mp-code-block"*/'platform/components/documentation/CodeBlock.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-anonymous-hidden') {
              return import(/* webpackChunkName: "mp-anonymous-hidden"*/'platform/components/security/AnonymousHidden.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-has-permission') {
              return import(/* webpackChunkName: "mp-has-permission"*/'platform/components/security/HasPermission.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-error') {
              return import(/* webpackChunkName: "mp-error"*/'platform/components/ui/notification/ErrorNotification.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-user-language-switch') {
              return import(/* webpackChunkName: "mp-user-language-switch"*/'platform/components/language-switch/UserLanguageSwitch.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-graph-store-action') {
              return import(/* webpackChunkName: "mp-graph-store-action"*/'platform/components/admin/rdf-upload/GraphActionLink.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-rdf-upload') {
              return import(/* webpackChunkName: "mp-rdf-upload"*/'platform/components/admin/rdf-upload/RdfUpload.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-file-upload') {
              return import(/* webpackChunkName: "mp-file-upload"*/'platform/components/admin/file-upload/FileUpload.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-app-upload') {
              return import(/* webpackChunkName: "mp-app-upload"*/'platform/components/admin/app-upload/AppUpload.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-page-loader') {
              return import(/* webpackChunkName: "mp-page-loader"*/'platform/components/ui/page-loader.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-form-file-input') {
              return import(/* webpackChunkName: "semantic-form-file-input"*/'platform/components/forms/file-manager/FileInput.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-direct-file-uploader') {
              return import(/* webpackChunkName: "mp-direct-file-uploader"*/'platform/components/forms/file-manager/DirectFileUploader.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-file-uploader') {
              return import(/* webpackChunkName: "mp-file-uploader"*/'platform/components/forms/file-manager/FileUploader.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-file-visualizer') {
              return import(/* webpackChunkName: "mp-file-visualizer"*/'platform/components/forms/file-manager/FileVisualizer.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-file-download') {
              return import(/* webpackChunkName: "rs-file-download"*/'platform/components/forms/file-manager/DownloadFile.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-file-remove') {
              return import(/* webpackChunkName: "rs-file-remove"*/'platform/components/forms/file-manager/RemoveFile.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-admin-page-manager') {
              return import(/* webpackChunkName: "mp-admin-page-manager"*/'platform/components/admin/page-manager/PageManager.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-admin-namespace-manager') {
              return import(/* webpackChunkName: "mp-admin-namespace-manager"*/'platform/components/admin/namespace-manager/NamespaceManager.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-admin-config-manager') {
              return import(/* webpackChunkName: "mp-admin-config-manager"*/'platform/components/admin/config-manager/ConfigManager.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-admin-cache-invalidation-button') {
              return import(/* webpackChunkName: "mp-admin-cache-invalidation-button"*/'platform/components/admin/CacheInvalidationButton.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-admin-repository-mgmt') {
              return import(/* webpackChunkName: "mp-admin-repository-mgmt"*/'platform/components/admin/repositories/RepositoryManager.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-admin-log-browser') {
              return import(/* webpackChunkName: "mp-admin-log-browser"*/'platform/components/admin/log-browser/LogBrowser.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-logging-profile-selector') {
              return import(/* webpackChunkName: "mp-logging-profile-selector"*/'platform/components/admin/log-browser/LoggingProfileSelector.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-restart-wrapper') {
              return import(/* webpackChunkName: "mp-restart-wrapper"*/'platform/components/admin/RestartWrapper.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-account-manager') {
              return import(/* webpackChunkName: "mp-account-manager"*/'platform/components/admin/realm-manager/AccountManagerComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-sparql-download') {
              return import(/* webpackChunkName: "mp-sparql-download"*/'platform/components/semantic/results/SparqlDownloadComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-sparql-result-counts') {
              return import(/* webpackChunkName: "mp-sparql-result-counts"*/'platform/components/semantic/results/ResultsNumber.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-ldp-remove-from-container-action') {
              return import(/* webpackChunkName: "mp-ldp-remove-from-container-action"*/'platform/components/ldp/RemoveFromContainerAction.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-ldp-create-new-resource-action') {
              return import(/* webpackChunkName: "mp-ldp-create-new-resource-action"*/'platform/components/ldp/CreateNewResource.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-ldp-duplicate-resource-action') {
              return import(/* webpackChunkName: "mp-ldp-duplicate-resource-action"*/'platform/components/ldp/DuplicateResource.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-ldp-export-resource') {
              return import(/* webpackChunkName: "mp-ldp-export-resource"*/'platform/components/ldp/ExportResource.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-ldp-import-resource') {
              return import(/* webpackChunkName: "mp-ldp-import-resource"*/'platform/components/ldp/ImportResource.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-overlay-dialog') {
              return import(/* webpackChunkName: "mp-overlay-dialog"*/'platform/components/ui/overlay/OverlayDialog.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-overlay-dialog-content') {
              return import(/* webpackChunkName: "mp-overlay-dialog-content"*/'platform/components/ui/overlay/OverlayDialogContent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-overlay-dialog-trigger') {
              return import(/* webpackChunkName: "mp-overlay-dialog-trigger"*/'platform/components/ui/overlay/OverlayDialogTrigger.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'ontology-create-new-resource-action') {
              return import(/* webpackChunkName: "ontology-create-new-resource-action"*/'platform/components/ldp/CreateNewOntologyResource.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-anchor') {
              return import(/* webpackChunkName: "mp-anchor"*/'platform/components/ui/scrollchor.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-platform-url') {
              return import(/* webpackChunkName: "mp-platform-url"*/'platform/components/ui/platform-url.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-nav-account') {
              return import(/* webpackChunkName: "mp-nav-account"*/'platform/app/navbar/NavAccountComponent').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-internal-page-editor') {
              return import(/* webpackChunkName: "mp-internal-page-editor"*/'platform/app/page/PageEditor.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-internal-sparql-endpoint') {
              return import(/* webpackChunkName: "mp-internal-sparql-endpoint"*/'platform/app/sparql/SparqlEndpoint.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-draggable') {
              return import(/* webpackChunkName: "mp-draggable"*/'platform/components/dnd/DraggableComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-print') {
              return import(/* webpackChunkName: "mp-print"*/'platform/components/ui/print/PrintComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-print-section') {
              return import(/* webpackChunkName: "mp-print-section"*/'platform/components/ui/print/PrintSectionComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-splitpane') {
              return import(/* webpackChunkName: "mp-splitpane"*/'platform/components/ui/splitpane/SplitPaneComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-splitpane-sidebar-closed') {
              return import(/* webpackChunkName: "mp-splitpane-sidebar-closed"*/'platform/components/ui/splitpane/SplitPaneSidebarClosedComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-splitpane-sidebar-open') {
              return import(/* webpackChunkName: "mp-splitpane-sidebar-open"*/'platform/components/ui/splitpane/SplitPaneSidebarOpenComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-splitpane-toggle-on') {
              return import(/* webpackChunkName: "mp-splitpane-toggle-on"*/'platform/components/ui/splitpane/SplitPaneToggleOnComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-splitpane-toggle-off') {
              return import(/* webpackChunkName: "mp-splitpane-toggle-off"*/'platform/components/ui/splitpane/SplitPaneToggleOffComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-label') {
              return import(/* webpackChunkName: "mp-label"*/'platform/components/ui/resource-label.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-highlight') {
              return import(/* webpackChunkName: "mp-highlight"*/'platform/components/ui/highlight/HighlightComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-popover') {
              return import(/* webpackChunkName: "mp-popover"*/'platform/components/ui/popover/PopoverComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-popover-trigger') {
              return import(/* webpackChunkName: "mp-popover-trigger"*/'platform/components/ui/popover/PopoverTriggerComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-popover-content') {
              return import(/* webpackChunkName: "mp-popover-content"*/'platform/components/ui/popover/PopoverContentComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-collapsible-div') {
              return import(/* webpackChunkName: "mp-collapsible-div"*/'platform/components/ui/collapsible-div/CollapsibleDivComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-collapsible-div-trigger') {
              return import(/* webpackChunkName: "mp-collapsible-div-trigger"*/'platform/components/ui/collapsible-div/CollapsibleDivTriggerComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-collapsible-div-content') {
              return import(/* webpackChunkName: "mp-collapsible-div-content"*/'platform/components/ui/collapsible-div/CollapsibleDivContentComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-panel') {
              return import(/* webpackChunkName: "mp-panel"*/'platform/components/ui/panel/Panel.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-panel-header') {
              return import(/* webpackChunkName: "mp-panel-header"*/'platform/components/ui/panel/PanelHeader.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-panel-body') {
              return import(/* webpackChunkName: "mp-panel-body"*/'platform/components/ui/panel/PanelBody.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-panel-footer') {
              return import(/* webpackChunkName: "mp-panel-footer"*/'platform/components/ui/panel/PanelFooter.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-text-truncate') {
              return import(/* webpackChunkName: "mp-text-truncate"*/'platform/components/ui/truncate/TextTruncateComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-set-management') {
              return import(/* webpackChunkName: "mp-set-management"*/'platform/components/sets/SetManagement.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-set-management-action-remove-set-item') {
              return import(/* webpackChunkName: "mp-set-management-action-remove-set-item"*/'platform/components/sets/actions/RemoveSetItemAction.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-set-management-action-fetch-set-items') {
              return import(/* webpackChunkName: "mp-set-management-action-fetch-set-items"*/'platform/components/sets/actions/FetchSetItemsAction.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-set-management-action-remove-set') {
              return import(/* webpackChunkName: "mp-set-management-action-remove-set"*/'platform/components/sets/actions/RemoveSetAction.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-set-management-action-manage-set') {
              return import(/* webpackChunkName: "mp-set-management-action-manage-set"*/'platform/components/sets/actions/ManageSetAction.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-set-management-action-rename-set') {
              return import(/* webpackChunkName: "mp-set-management-action-rename-set"*/'platform/components/sets/actions/RenameSetAction.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-copy-to-default-set') {
              return import(/* webpackChunkName: "mp-copy-to-default-set"*/'platform/components/sets/CopyToDefaultSetActionComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-set-management-single-set') {
              return import(/* webpackChunkName: "mp-set-management-single-set"*/'platform/components/sets/SingleSet.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-create-set-action') {
              return import(/* webpackChunkName: "mp-create-set-action"*/'platform/components/sets/CreateSetActionComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-component-toolbar') {
              return import(/* webpackChunkName: "mp-component-toolbar"*/'platform/components/persistence/ComponentToolbar.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-component-toolbar-actions') {
              return import(/* webpackChunkName: "mp-component-toolbar-actions"*/'platform/components/persistence/ComponentToolbarActions.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-component-toolbar-component') {
              return import(/* webpackChunkName: "mp-component-toolbar-component"*/'platform/components/persistence/ComponentToolbarComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-component-toolbar-action-download') {
              return import(/* webpackChunkName: "mp-component-toolbar-action-download"*/'platform/components/persistence/ActionDownload.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-component-toolbar-action-save') {
              return import(/* webpackChunkName: "mp-component-toolbar-action-save"*/'platform/components/persistence/ActionSave.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-persisted-component') {
              return import(/* webpackChunkName: "mp-persisted-component"*/'platform/components/persistence/PersistedComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-url-minifier') {
              return import(/* webpackChunkName: "mp-url-minifier"*/'platform/components/ui/url-minifier.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-documentation') {
              return import(/* webpackChunkName: "mp-documentation"*/'platform/components/documentation/ConfigDocComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-spin-query-template-editor') {
              return import(/* webpackChunkName: "mp-spin-query-template-editor"*/'platform/components/query-editor/QueryTemplate.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-spin-query-editor') {
              return import(/* webpackChunkName: "mp-spin-query-editor"*/'platform/components/query-editor/QueryEditorComponent.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-sparql-query-editor') {
              return import(/* webpackChunkName: "mp-sparql-query-editor"*/'platform/components/sparql-editor/SparqlQueryEditor.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-sparql-query-editor-context') {
              return import(/* webpackChunkName: "mp-sparql-query-editor-context"*/'platform/components/sparql-editor/SparqlQueryEditorContext.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-recent-queries') {
              return import(/* webpackChunkName: "mp-recent-queries"*/'platform/components/sparql-editor/RecentQueries.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-query-catalog-template') {
              return import(/* webpackChunkName: "mp-query-catalog-template"*/'platform/components/sparql-editor/QueryCatalogTemplate.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-selection-toggle') {
              return import(/* webpackChunkName: "mp-selection-toggle"*/'platform/components/ui/selection/SelectionToggleComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-selection-action-choice') {
              return import(/* webpackChunkName: "mp-selection-action-choice"*/'platform/components/ui/selection/SelectionActionChoiceComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-selection-group') {
              return import(/* webpackChunkName: "mp-selection-group"*/'platform/components/ui/selection/SelectionGroupComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'ontodia') {
              return import(/* webpackChunkName: "ontodia"*/'platform/components/3-rd-party/ontodia/Ontodia.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'diagram-search-result') {
              return import(/* webpackChunkName: "diagram-search-result"*/'platform/components/3-rd-party/ontodia/DiagramSearchResult.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'ontodia-nested-nodes') {
              return import(/* webpackChunkName: "ontodia-nested-nodes"*/'platform/components/3-rd-party/ontodia/OntodiaNestedNodes.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'ontodia-entity-metadata') {
              return import(/* webpackChunkName: "ontodia-entity-metadata"*/'platform/components/3-rd-party/ontodia/authoring/OntodiaEntityMetadata.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'ontodia-contents') {
              return import(/* webpackChunkName: "ontodia-contents"*/'platform/components/3-rd-party/ontodia/OntodiaContents.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'ontodia-field-configuration') {
              return import(/* webpackChunkName: "ontodia-field-configuration"*/'platform/components/3-rd-party/ontodia/authoring/OntodiaFieldConfiguration.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'ontodia-field-input-override') {
              return import(/* webpackChunkName: "ontodia-field-input-override"*/'platform/components/3-rd-party/ontodia/authoring/FieldInputOverride.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-copy-to-clipboard') {
              return import(/* webpackChunkName: "mp-copy-to-clipboard"*/'platform/components/copy-to-clipboard/CopyToClipboardComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-share-link') {
              return import(/* webpackChunkName: "rs-share-link"*/'platform/components/copy-to-clipboard/ShareLinkComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-lang-literal') {
              return import(/* webpackChunkName: "mp-lang-literal"*/'platform/components/ui/lang-literal/LangLiteral.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-json-renderer') {
              return import(/* webpackChunkName: "mp-json-renderer"*/'platform/components/ui/json/json-renderer.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-file-download') {
              return import(/* webpackChunkName: "mp-file-download"*/'platform/components/ui/file-download.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-workflow-create') {
              return import(/* webpackChunkName: "mp-workflow-create"*/'platform/components/workflow/WorkflowCreateComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-workflow-manager') {
              return import(/* webpackChunkName: "mp-workflow-manager"*/'platform/components/workflow/WorkflowManagerComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'mp-workflow-selection-action') {
              return import(/* webpackChunkName: "mp-workflow-selection-action"*/'platform/components/workflow/WorkflowSelectionAction.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-row') {
              return import(/* webpackChunkName: "bs-row"*/'react-bootstrap/es/Row.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-col') {
              return import(/* webpackChunkName: "bs-col"*/'react-bootstrap/es/Col.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-tabs') {
              return import(/* webpackChunkName: "bs-tabs"*/'react-bootstrap/es/Tabs.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-tab') {
              return import(/* webpackChunkName: "bs-tab"*/'react-bootstrap/es/Tab.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-tab-container') {
              return import(/* webpackChunkName: "bs-tab-container"*/'react-bootstrap/es/TabContainer.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-tab-pane') {
              return import(/* webpackChunkName: "bs-tab-pane"*/'react-bootstrap/es/TabPane.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-tab-content') {
              return import(/* webpackChunkName: "bs-tab-content"*/'react-bootstrap/es/TabContent.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-panel') {
              return import(/* webpackChunkName: "bs-panel"*/'react-bootstrap/es/Panel.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-panel-group') {
              return import(/* webpackChunkName: "bs-panel-group"*/'react-bootstrap/es/PanelGroup.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-navbar') {
              return import(/* webpackChunkName: "bs-navbar"*/'react-bootstrap/es/Navbar.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-navbar-brand') {
              return import(/* webpackChunkName: "bs-navbar-brand"*/'react-bootstrap/es/NavbarBrand.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-navbar-collapse') {
              return import(/* webpackChunkName: "bs-navbar-collapse"*/'react-bootstrap/es/NavbarCollapse.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-navbar-header') {
              return import(/* webpackChunkName: "bs-navbar-header"*/'react-bootstrap/es/NavbarHeader.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-navbar-toggle') {
              return import(/* webpackChunkName: "bs-navbar-toggle"*/'react-bootstrap/es/NavbarToggle.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-nav') {
              return import(/* webpackChunkName: "bs-nav"*/'react-bootstrap/es/Nav.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-nav-item') {
              return import(/* webpackChunkName: "bs-nav-item"*/'react-bootstrap/es/NavItem.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-nav-dropdown') {
              return import(/* webpackChunkName: "bs-nav-dropdown"*/'react-bootstrap/es/NavDropdown.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-menu-item') {
              return import(/* webpackChunkName: "bs-menu-item"*/'react-bootstrap/es/MenuItem.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-dropdown-button') {
              return import(/* webpackChunkName: "bs-dropdown-button"*/'react-bootstrap/es/DropdownButton.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-alert') {
              return import(/* webpackChunkName: "bs-alert"*/'react-bootstrap/es/Alert.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-dropdown') {
              return import(/* webpackChunkName: "bs-dropdown"*/'react-bootstrap/es/Dropdown.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-dropdown-menu') {
              return import(/* webpackChunkName: "bs-dropdown-menu"*/'react-bootstrap/es/DropdownMenu.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-dropdown-toggle') {
              return import(/* webpackChunkName: "bs-dropdown-toggle"*/'react-bootstrap/es/DropdownToggle.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'bs-progress-bar') {
              return import(/* webpackChunkName: "bs-progress-bar"*/'react-bootstrap/es/ProgressBar.js').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-iiif-image-thumbnail') {
              return import(/* webpackChunkName: "rs-iiif-image-thumbnail"*/'platform/components/iiif/ImageThumbnail.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-image-upload') {
              return import(/* webpackChunkName: "rs-image-upload"*/'platform/widgets/image-upload-widget.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-iiif-overlay') {
              return import(/* webpackChunkName: "rs-iiif-overlay"*/'platform/components/iiif/OverlayComparison.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-iiif-side-by-side') {
              return import(/* webpackChunkName: "rs-iiif-side-by-side"*/'platform/components/iiif/SideBySideComparison.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-iiif-overlay-action') {
              return import(/* webpackChunkName: "rs-iiif-overlay-action"*/'platform/components/iiif/actions/OverlayComparisonActionComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-iiif-side-by-side-action') {
              return import(/* webpackChunkName: "rs-iiif-side-by-side-action"*/'platform/components/iiif/actions/SideBySideComparisonActionComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-iiif-mirador') {
              return import(/* webpackChunkName: "rs-iiif-mirador"*/'platform/components/iiif/ImageRegionEditor.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-argument-simple-belief-selector') {
              return import(/* webpackChunkName: "rs-argument-simple-belief-selector"*/'platform/components/arguments/Belief.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-quick-assertion') {
              return import(/* webpackChunkName: "rs-quick-assertion"*/'platform/components/arguments/QuickAssertionComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-simple-assertion') {
              return import(/* webpackChunkName: "rs-simple-assertion"*/'platform/components/arguments/SimpleAssertion.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-assertion') {
              return import(/* webpackChunkName: "rs-assertion"*/'platform/components/arguments/AssertionComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-premise') {
              return import(/* webpackChunkName: "rs-premise"*/'platform/components/arguments/premise/PremiseComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-object-representations') {
              return import(/* webpackChunkName: "rs-object-representations"*/'platform/widgets/ObjectRepresentationsWidget.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-visibility') {
              return import(/* webpackChunkName: "rs-visibility"*/'platform/components/common/ShareComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-collapsible') {
              return import(/* webpackChunkName: "rs-collapsible"*/'platform/components/collapsible/CollapsibleComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-browse-history') {
              return import(/* webpackChunkName: "rs-browse-history"*/'platform/widgets/BrowseHistoryComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-alignment-tool') {
              return import(/* webpackChunkName: "rs-alignment-tool"*/'platform/components/alignment/AlignmentTool.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-alignment-tool-create-alignment-action') {
              return import(/* webpackChunkName: "rs-alignment-tool-create-alignment-action"*/'platform/components/alignment/CreateAlignmentAction.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-result-context') {
              return import(/* webpackChunkName: "semantic-search-result-context"*/'platform/components/search/web-components/SemanticSearchContextualizedResult.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-facet') {
              return import(/* webpackChunkName: "semantic-search-facet"*/'platform/components/search/web-components/SemanticSearchFacet.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-facet-breadcrumbs') {
              return import(/* webpackChunkName: "semantic-search-facet-breadcrumbs"*/'platform/components/search/web-components/SemanticSearchFacetBreadcrumbs.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-query-builder') {
              return import(/* webpackChunkName: "semantic-search-query-builder"*/'platform/components/search/query-builder/QueryBuilder.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-action-use-result-in-extended-search') {
              return import(/* webpackChunkName: "semantic-search-action-use-result-in-extended-search"*/'platform/components/search/web-components/SemanticSearchUseResultInExtendedSearchAction.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-action-use-result-item-in-extended-search') {
              return import(/* webpackChunkName: "semantic-search-action-use-result-item-in-extended-search"*/'platform/components/search/web-components/SemanticSearchUseResultItemInExtendedSearchAction.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-action-save-set-result') {
              return import(/* webpackChunkName: "semantic-search-action-save-set-result"*/'platform/components/search/web-components/SemanticSearchSaveSetResultAction.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-action-save-search-result') {
              return import(/* webpackChunkName: "semantic-search-action-save-search-result"*/'platform/components/search/web-components/SemanticSearchSaveSearchResultAction.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-search-configuration-editor') {
              return import(/* webpackChunkName: "semantic-search-configuration-editor"*/'platform/components/search/configuration/ConfigurationSelector').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'semantic-timeline') {
              return import(/* webpackChunkName: "semantic-timeline"*/'platform/components/timeline/SemanticTimeline.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-guided-tour') {
              return import(/* webpackChunkName: "rs-guided-tour"*/'platform/components/guided-tour/GuidedTour.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-panel-system') {
              return import(/* webpackChunkName: "rs-panel-system"*/'platform/components/panel-system/PanelSystem.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-panel-system-holder') {
              return import(/* webpackChunkName: "rs-panel-system-holder"*/'platform/components/panel-system/PanelSystemHolder.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-iiif-viewer-panel') {
              return import(/* webpackChunkName: "rs-iiif-viewer-panel"*/'platform/components/panel-system/IIIFViewerPanel.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-ontodia-panel') {
              return import(/* webpackChunkName: "rs-ontodia-panel"*/'platform/components/panel-system/OntodiaPanel.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-ontodia-metadata-from-fields') {
              return import(/* webpackChunkName: "rs-ontodia-metadata-from-fields"*/'platform/components/ontodia/MetadataFromFields.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-text-annotation-workspace') {
              return import(/* webpackChunkName: "rs-text-annotation-workspace"*/'platform/components/text-annotation/components/TextAnnotationWorkspace.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-text-annotation-type') {
              return import(/* webpackChunkName: "rs-text-annotation-type"*/'platform/components/text-annotation/components/TextAnnotationType.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-dashboard') {
              return import(/* webpackChunkName: "rs-dashboard"*/'platform/components/dashboard/DashboardComponent.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-image-graph-authoring') {
              return import(/* webpackChunkName: "rs-image-graph-authoring"*/'platform/components/iiif/image-graph-authoring/ImageGraphAuthoring.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'with-types') {
              return import(/* webpackChunkName: "with-types"*/'platform/components/ui/WithTypes.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-text-editor') {
              return import(/* webpackChunkName: "rs-text-editor"*/'platform/components/text-editor/TextEditor.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'two-side-panel') {
              return import(/* webpackChunkName: "two-side-panel"*/'platform/components/ui/two-side-panel/TwoSidePanel.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'inline-template') {
              return import(/* webpackChunkName: "inline-template"*/'platform/components/ui/template/InlineTemplate.ts').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'entity-view') {
              return import(/* webpackChunkName: "entity-view"*/'platform/components/ui/entity-view/EntityView.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'entity-view-search-results') {
              return import(/* webpackChunkName: "entity-view-search-results"*/'platform/components/ui/entity-view/EntityViewSearchResults.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'entity-finder') {
              return import(/* webpackChunkName: "entity-finder"*/'platform/components/ui/entity-view/Finder.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-icon') {
              return import(/* webpackChunkName: "rs-icon"*/'platform/components/ui/icon/Icon.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-back-button') {
              return import(/* webpackChunkName: "rs-back-button"*/'platform/components/ui/buttons/BackButton.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-tabs') {
              return import(/* webpackChunkName: "rs-tabs"*/'platform/components/ui/tabs/Tabs.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-tab') {
              return import(/* webpackChunkName: "rs-tab"*/'platform/components/ui/tabs/Tab.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
            if(tagName === 'rs-resource-dropdown') {
              return import(/* webpackChunkName: "rs-resource-dropdown"*/'platform/components/ui/resource-dropdown/index.tsx').then(
                function(comp) {
                  onLoaded(comp);
                  return comp;
                }
              );
            }
          
  };